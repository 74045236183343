<template>
  <div class="package-form">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <span class="title">Edit Package</span>
          </template>
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
            <form @submit.prevent="handleSubmit(save)">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <text-input name="Package Name" v-model="package.name" label="Package Name" :validate="'required'"
                    :maxlength="40" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <text-input name="Price" v-model="package.price" step="any" label="Price" type="number"
                    :disabled="package.is_free" />
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="control-label my-2">Is Free</div>
                  <vuestic-switch v-model="package.is_free" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
              </div>
              <fieldset>
                <legend>Attributes</legend>
                <table class="table table-bordered table-features">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>SMS Credit</span>
                      </td>
                      <td>
                        <text-input v-model="package.sms_credit" type="number" name="smsCredit" validate="required" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Call Credit</span>
                      </td>
                      <td>
                        <text-input v-model="package.voice_credit" type="number" name="callCredit"
                          validate="required" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
              <div class="row mt-5">
                <div class="col-md-12 d-flex justify-content-between">
                  <router-link class="btn btn-danger btn-primary" :to="{ name: 'agency.packages' }">
                    Back
                  </router-link>
                  <button class="btn btn-primary" :disabled="invalid">Update</button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgencyPackageEdit',
  components: {  },

  data() {
    return {
      loading: false,
      agency: null,
      package: {
        is_free: false
      },
    }
  },

  mounted() {
    this.getPackage()
  },

  methods: {
    save() {
      this.loading = true

      const param = {
        id: this.package.id,
        data: this.package,
      }

      this.$store
        .dispatch('package/update', param)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
    },

    getPackage() {
      this.loading = true;

      this.$store
        .dispatch('package/get', this.$route.params.id)
        .then((data) => {
          this.package = data
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({ name: 'agency.packages.index' });
          this.loading = false;
        })
    },
  },

  computed: {
  },
}
</script>

<style lang="scss" scoped>
.package-form {
  max-width: 1024px;
}

.atom-spinner {
  display: inline-block;
}

.table-features {
  thead th {
    text-transform: capitalize;
  }

  td {
    padding: 10px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 5px;
}
</style>

<style lang="scss">
.table-features {
  td .form-group {
    margin-bottom: 0;
  }
}
</style>
